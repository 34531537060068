import React, { createContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import StakeUnstakeTab from '../../components/tools/staking/StakeUnstakeTab';
import StakingProjectInfo, { ProgressBar, StakedStatus } from '../../components/tools/staking/StakingProjectInfo';
import SolanaWalletProvider from '../../components/utility/WalletConnect/SolanaWalletProvider';
import { setProjectData, setStakingProjectInfo } from '../../javascript/tools/staking';
import PageNotFound from '../utility/PageNotFound';
import Alert from './../../components/utility/Alert.js';
import './../../css/tools/staking.css';
import { Helmet } from 'react-helmet';
import preloader from './../../assets/img/utility/preloader.gif';
import StakingRewards from '../../components/tools/staking/StakingRewards';
import { Config } from '../../config';
import { SignModal } from '../../components/utility/Modals';
import PoweredBy from '../../components/utility/PoweredBy';

export const StakingContext = createContext({
  project: undefined,
  walletAddress: undefined,
  setWalletAddress: undefined,
  stakingProject: undefined,
  stakingRewards: undefined,
  displaySignModalState: undefined,
  alertState: undefined
});

export default function Staking() {
  const [alert, setAlert] = useState([]);
  const [pageError, setPageError] = useState('Not Loaded Yet');
  const [project, setProject] = useState();
  const [stakingProject, setStakingProject] = useState();
  const [walletAddress, setWalletAddress] = useState();
  const [projectCss, setProjectCss] = useState();
  const [openedStakeUnstakeTab, setOpenedStakeUnstakeTab] = useState('stake');
  const [stakingRewards, setStakingRewards] = useState([]);
  const [displaySignModal, setDisplaySignModal] = useState(false);
  let params = useParams();

  useEffect(() => {
    if (!window.location.hostname.includes('etakit') && !window.location.hostname.includes('localhost')) {
      const project = Config.domains.find((x) => window.location.hostname.includes(x.name));
      if (project) params = project
    }
    setProjectData(params, setPageError, setProject);
  }, [params, walletAddress]);

  useEffect(() => {
    setStakingProjectInfo(params.id, setPageError, walletAddress, setStakingProject, setStakingRewards);
  }, [walletAddress, params.id]);

  useEffect(() => {
    if (project?.cssLink) {
      async function importFile() {
        await import(`./../../css/custom/${project.id}.css`);
      }
      importFile();
    }
  }, [project]);
  const embedTitles = [
    {
      projectId: 'bullyz',
      title: 'Bullyz Crew - The Harbor',
      description: 'Welcome to the Harbor. From here, you can stake and send your Bullyz into raiding parties!\nIf you are lucky, they might come back with some loot every day.',
    },
  ];


  const [title, setTitle] = useState('Etakit')
  const [description, setDesciption] = useState('Etakit provides a toolkit for NFT projects including Staking, Holder Verification, Raffles, and much more!')

  useEffect(() => {
    let checkEmbed = Config.domains.find((x) => window.location.hostname.includes(x.name))

    const embedTitle = embedTitles.find((z) => z.projectId === (checkEmbed?.id || params.id));

    const newTitle = embedTitle ? embedTitle.title : 'Etakit';
    const newDescription = embedTitle ? embedTitle.description : 'Etakit provides a toolkit for NFT projects including Staking, Holder Verification, Raffles, and much more!';
    setTitle(newTitle);
    setDesciption(newDescription)
  }, [params.id])

  if (pageError === true) {
    return <PageNotFound />;
  }

  if (project && stakingProject) {

    return (
      <>
        {project.cssFile && (<link rel='stylesheet' href={project.cssFile} />)}
        <Helmet>
          <title>{title}</title>
          <meta name="title" content={title} />
          <meta name="description" content={description} />

          <meta property="og:type" content="website" />
          <meta property="og:url" content={window.location.href} />
          <meta property="og:title" content={title} />
          <meta property="og:description" content={description} />
          <meta property="og:image" content={project.logo} />

          <meta property="twitter:card" content="summary_large_image" />
          <meta property="twitter:url" content={window.location.href} />
          <meta property="twitter:title" content={title} />
          <meta property="twitter:description" content={description} />
          <meta property="twitter:image" content={project.logo} />

        </Helmet>

        <StakingContext.Provider
          value={{
            project,
            walletAddress,
            setWalletAddress,
            stakingProject,
            stakingRewards,
            alertState: [alert, setAlert],
            displaySignModalState: [displaySignModal, setDisplaySignModal],
          }}
        >
          <SolanaWalletProvider>
            <SignModal displayModal={displaySignModal} setDisplayModal={setDisplaySignModal} />
            <div className="staking-tool__bg">
              <div className="staking-tool__bg-inner">
                <StakingProjectInfo project={project} walletAddress={[walletAddress, setWalletAddress]} stakingProject={stakingProject} />
                <Alert alertState={[alert, setAlert]} />
                <div className="staking__flex-container">
                  <div>
                    <StakedStatus />
                  </div>
                  <div style={{ width: '700px', maxWidth: '80vw' }}>
                    <ProgressBar />
                    <StakingRewards setAlert={setAlert} setStakingRewards={setStakingRewards} />
                    <StakeUnstakeTab openedStakeUnstakeTab={[openedStakeUnstakeTab, setOpenedStakeUnstakeTab]} setAlert={setAlert} setStakingProject={setStakingProject} />
                  </div>
                </div>
              </div>
              <PoweredBy />
            </div>
          </SolanaWalletProvider>
        </StakingContext.Provider>
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="title" content={title} />
        <meta name="description" content={description} />

        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        {/* <meta property="og:image" content={project.logo} /> */}

        {/* <meta property="twitter:card" content="summary_large_image" /> */}
        <meta property="twitter:url" content={window.location.href} />
        <meta property="twitter:title" content={title} />
        <meta property="twitter:description" content={description} />
        {/* <meta property="twitter:image" content={project.logo} /> */}
      </Helmet>


      <div style={{ marginTop: '20px', minWidth: '100vw', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <div className="font-signika" style={{ borderRadius: '15px', color: 'white', textAlign: 'center', maxWidth: '80vw', fontSize: '2.5rem', fontWeight: '700', paddingLeft: '25px', paddingRight: '25px', paddingTop: '15px', paddingBottom: '15px', height: 'fit-content' }}>
          <img src={preloader} style={{ width: '250px', maxWidth: '60vw' }} alt="preloader" />
        </div>
      </div>
    </>
  )
}