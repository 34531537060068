import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { StakingContext } from '../../pages/tools/Staking'

export default function PoweredBy() {
    const { project } = useContext(StakingContext)
    if (!project.poweredBy) {
        return (<></>)
    } else {
        return (
            <div className='utility__powered-by'>
                <div>Powered By </div>{project.poweredBy.map((x, i) => {
                    return (<div key={i}>{x.logo ? (<img src={x.logo} alt={x.name} style={{marginLeft: '0.2rem'}}/>) : (<></>)} {x.name} {x.twitter ? (<a href={x.twitter}><img src={'https://media.discordapp.net/attachments/881015143369175051/1076204026313588848/twitter_circle-512.png'} alt={''} style={{marginLeft: '0.2rem'}}/></a>) : (<></>)} {x.discord ? (<a href={x.discord}><img src={'https://media.discordapp.net/attachments/881015143369175051/1076203462401994803/713v8Bxcy4rHrjbDgAAAAASUVORK5CYII.png'} alt={''} style={{marginLeft: '0.2rem'}}/></a>) : (<></>)}</div>)
                })}
            </div>
        )
    }
}
