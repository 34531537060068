import React from 'react'

export default function PageNotFound() {
    return (
        <div style={{ backgroundColor: '#221450', minWidth: '100vw', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div className='font-robot' style={{ borderRadius: '15px', color: 'white', border: '5px solid white', display: 'flex', alignItems: 'center', textAlign: 'center', height: '250px', width: '250px', maxWidth: '80vw', background: 'linear-gradient(to bottom right, #3b38ad, #141251)', fontSize: '3rem', padding: '30px' }}>
                Page not Found
            </div>
        </div>
    )
}
