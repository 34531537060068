import { Button } from '@mui/material'
import { useConnection, useWallet } from '@solana/wallet-adapter-react'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { unstakeNft } from '../../../javascript/tools/staking'
import { nFormatter } from '../../../javascript/utility/utility'
import { StakingContext } from '../../../pages/tools/Staking'
import preloader from './../../../assets/img/utility/preloader.gif'
import { LazyLoadComponent, LazyLoadImage } from 'react-lazy-load-image-component'

export default function UnStakeNFTs({ setAlert, setStakingProject }) {
  const { stakingProject, project, displaySignModalState } = useContext(StakingContext)
  const [, setDisplaySignModal] = displaySignModalState

  const [nftsToUnstake, setNftsToUnstake] = useState([])
  const { signMessage, signTransaction, publicKey } = useWallet()
  const stakedNfts = stakingProject.stakedNfts
  const [disableUnstakeBtn, setDisableUnstakeBtn] = useState(false)
  const { connection } = useConnection()

  if (!publicKey) {
    return (<div className='staking-tool__red-border-container staking-tool__red-border-container-align-center'>
      <div className='font-share-tech' style={{ color: 'white', display: 'flex', alignItems: 'center', textAlign: 'center', maxWidth: '80%', fontSize: '1.5rem', paddingLeft: '25px', paddingRight: '25px', paddingTop: '15px', paddingBottom: '15px', height: 'fit-content' }}>
        You have not connected your wallet
      </div>
    </div>
    )
  }

  if (!stakingProject.nftsOfCollection) {
    return (
      <div className='staking-tool__red-border-container staking-tool__red-border-container-align-center'>
        <div className='font-signika' style={{ borderRadius: '15px', color: 'white', display: 'flex', alignItems: 'center', textAlign: 'center', maxWidth: '80vw', fontSize: '2.5rem', fontWeight: '700', paddingLeft: '25px', paddingRight: '25px', paddingTop: '15px', paddingBottom: '15px', height: 'fit-content' }}>
          <img src={preloader} style={{ width: '150px', maxWidth: '60%' }} alt='preloader' />
        </div>
      </div>
    )
  }
  if (!stakedNfts) {
    return (<></>)
  }
  if (!stakedNfts.length) {
    return (
      <div className='staking-tool__red-border-container staking-tool__red-border-container-align-center'>
        <div className='font-share-tech' style={{ color: 'white', display: 'flex', alignItems: 'center', textAlign: 'center', maxWidth: '80%', fontSize: '1.5rem', paddingLeft: '25px', paddingRight: '25px', paddingTop: '15px', paddingBottom: '15px', height: 'fit-content' }}>
          You do not have any {project.name} NFT to Unstake
        </div>
      </div>
    )
  }

  const unstakeNFTs = (event) => {
    event.preventDefault()
    unstakeNft(publicKey, project, nftsToUnstake, signMessage, setAlert, setStakingProject, setDisableUnstakeBtn, stakingProject, signTransaction, connection, setDisplaySignModal)
  }
  return (
    <div style={{ width: '100%' }}>
      <div className='staking-tool__red-border-container'>
        <form onChange={(event) => updateNftsToUnstake(event, setNftsToUnstake, stakedNfts)} id='staking-tool__unstake-form' onSubmit={(event) => event.preventDefault()} style={{ paddingBottom: '20px' }}>
          {stakedNfts.length && (<SelectAllBtn setNftsToUnstake={setNftsToUnstake} stakedNfts={stakedNfts} />)}
          <div className='staking-tool__stake-unstake-flex-container'>
            <LazyLoadComponent>
              {stakedNfts.map(x => {
                const option = stakingProject.stakingOptions.find(z => z.rewards.find(y => y.planId === x.staked.planId))
                let text = ''
                if (option) {
                  let plan = option.rewards.find(y => y.planId === x.staked.planId)
                  if (x.stakingOptions && x.stakingOptions.find(y => y.planId === x.staked.planId)) {
                    plan = x.stakingOptions.find(y => y.planId === x.staked.planId)
                  }
                  if (plan) text = `${nFormatter(plan.amount)} ${plan.currency}${option.locked ? `/${option.interval === 1 ? '' : `${option.interval} `}${option.interval === 1 ? 'Day' : 'Days'} - Locked` : '/Day - Unlocked'}`
                }
                return (
                  <div key={x.mintAddress} style={{ background: 'linear-gradient(to bottom right, #835bffd6, #44206dc7)', margin: '0.2rem' }}>
                    <input type={'checkbox'} id={x.mintAddress} className='staking-tool__stake-unstake-nft-input-checkbox' />
                    <label htmlFor={x.mintAddress} className='staking-tool__stake-unstake-nft-label'>
                      <div className='staking-tool__stake-unstake-nft' style={{ border: '0px' }}>
                        <LazyLoadImage src={x.image} style={{ width: '100%', border: '0px' }} alt={x.mintAddress} />
                      </div>
                      <div style={{ display: 'block', textAlign: 'center', color: 'white', margin: '0.2rem', fontSize: '0.6rem' }} className='font-signika'>
                        {x.name && (<div className='staking-tool__stake-unstake-nft-name'>{x.name.replace(project.name, '')}</div>)}
                        <div className='staking-tool__stake-unstake-nft-reward-rate'>{text}</div>
                        <EndingTimeTimer deadline={x.staked.nextReward} />
                      </div>
                    </label>
                  </div>
                )
              })}
            </LazyLoadComponent>
          </div>
        </form>
      </div>
      {project?.id === 'hasuki' ?
        (<></>) :
        (<button className='staking-tool_stake-unstake-form-submit-btn' disabled={disableUnstakeBtn} style={{ margin: '0.5rem auto' }} onClick={(event) => unstakeNFTs(event)}>Unstake</button>)
      }
    </div>
  )
}

const SelectAllBtn = ({ setNftsToUnstake, stakedNfts }) => {
  const selectAll = async () => {
    let inputs = []
    const form = document.getElementById('staking-tool__unstake-form')
    for (var i = 0; i < form.length; i++) {
      inputs.push(form.elements[i])
    }
    let unchecked = inputs.filter(x => x.type === 'checkbox' && !x.checked)
    if (unchecked.length) {
      unchecked.forEach(x => {
        if (x.type === 'checkbox') {
          x.checked = true
        }
      })
    } else {
      inputs.forEach(x => {
        x.checked = false
      })
    }
    updateNftsToUnstake('', setNftsToUnstake, stakedNfts)
  }
  return (
    <Button onClick={selectAll} style={{
      backgroundColor: 'rgb(127, 127, 237)',
      border: '4px solid rgb(84 84 166)',
      color: 'white',
      margin: 'auto',
      display: 'block'
    }}>
      Select All
    </Button>
  )
}

const SECOND = 1000;
const MINUTE = SECOND * 60;
const HOUR = MINUTE * 60;
const DAY = HOUR * 24;

export const EndingTimeTimer = ({ deadline = new Date().toString() }) => {
  const parsedDeadline = useMemo(() => new Date(deadline), [deadline]);
  const [time, setTime] = useState(parsedDeadline - Date.now());

  useEffect(() => {
    const interval = setInterval(
      () => setTime(parsedDeadline - Date.now()),
      1000,
    );
    return () => clearInterval(interval);
  }, [parsedDeadline]);

  if (time >= 0) {
    return (
      <div className="staking-tool__reward-time-timer" style={{ padding: "5px", display: "block", margin: "auto" }}>
        <div className='font-share-tech' style={{ display: "block", margin: "auto", textAlign: "center", color: "white", fontSize: "0.8rem" }}>Next Reward</div>
        <div className="staking-tool__reward-time-timer">
          {Object.entries({
            'D': time / DAY,
            'H': (time / HOUR) % 24,
            'M': (time / MINUTE) % 60,
            'S': (time / SECOND) % 60,
          }).map(([label, value]) => (
            <div key={label} className="staking-tool__reward-time-timer-columns font-montserrat">
              <div className="staking-timer-time font-montserrat">
                <div>{`${Math.floor(value)}`.padStart(2, "0")}{label}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  } else {
    return (
      <></>

    )
  }
};

const updateNftsToUnstake = (event, setNftsToUnstake, stakedNfts) => {
  let answers = document.getElementById('staking-tool__unstake-form')
  const nftsChosenArray = []
  for (var i = 0; i < answers.length; i++) {
    if (answers.elements[i].type !== 'submit') {
      nftsChosenArray.push({ mint: answers.elements[i].id, checked: answers.elements[i].checked });
    }
  }
  let nftsToStakeArr = nftsChosenArray.filter(x => x.checked === true)
  if (!nftsToStakeArr.length) {
    setNftsToUnstake([])
  } else {
    const nftsToStake = stakedNfts.filter(x => nftsToStakeArr.find(y => y.mint === x.mintAddress))
    setNftsToUnstake(nftsToStake)
  }
}
