import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Tab } from '@mui/material'
import React from 'react'
import StakeNFTs from './StakeNFTs';
import UnStakeNFTs from './UnStakeNFTs';
import { useWallet } from '@solana/wallet-adapter-react';
export default function StakeUnstakeTab(props) {
    const [openedStakeUnstakeTab, setOpenedStakeUnstakeTab] = props.openedStakeUnstakeTab
    const setStakingProject = props.setStakingProject
    const handleChange = (event, newValue) => {
        setOpenedStakeUnstakeTab(newValue);
    };
    const setAlert = props.setAlert
    return (
        <div>
            <TabContext value={openedStakeUnstakeTab}>
                <TabList onChange={handleChange} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: 'fit-content', margin: 'auto' }} TabIndicatorProps={{
                    style: { display: 'none' }
                }}>
                    <Tab label="Unstaked" value="stake" className='staking-tool__stake-unstake-tab-btn' />
                    <Tab label="Staked" value="unstake" className='staking-tool__stake-unstake-tab-btn' />
                </TabList>
                <TabPanel value="stake" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '0px' }}><StakeNFTs setAlert={setAlert} setStakingProject={setStakingProject} /></TabPanel>
                <TabPanel value="unstake" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '0px' }}><UnStakeNFTs setAlert={setAlert} setStakingProject={setStakingProject} /></TabPanel>
            </TabContext>
        </div>
    )
}

