import { useContext, useState } from 'react'
import { useConnection, useWallet } from '@solana/wallet-adapter-react'
import { verifyWallet } from '../../javascript/utility/utility'
import { StakingContext } from '../../pages/tools/Staking'
import { FormControlLabel, Switch } from '@mui/material'
import loader from './../../assets/img/utility/preloader.gif'
export const SignModal = ({ displayModal, setDisplayModal }) => {
    const { signMessage, publicKey, sendTransaction } = useWallet()
    const { connection } = useConnection()
    const { alertState, project } = useContext(StakingContext)
    const [, setAlert] = alertState
    const [ledger, setLedger] = useState(false)
    const [disableSignBtn, setDisableSignBtn] = useState(false)
    if (!displayModal) {
        return (<></>)
    } else {
        return (
            <>
                <div className='modal-wrapper' style={!displayModal ? { display: 'none' } : {}}>
                    <div className='modal-bg'></div>
                    <div className='modal-container'>
                        <div className='modal'>
                            <div style={{ justifyContent: 'center', textAlign: 'center', color: 'white', fontFamily: 'Montserrat', fontSize: '1.5rem', fontWeight: '600', padding: '2rem' }}>Sign Message
                                <div style={{ fontSize: '1rem', marginTop: '1.5rem' }}>You need to sign message to verify ownership of wallet</div>
                                <div style={{ margin: '0.8rem' }}>
                                    <FormControlLabel style={{
                                        padding: '0.1rem 0.8rem 0.1rem 0.8rem',
                                        background: '#39394a52',
                                        borderRadius: '200px'
                                    }} control={<Switch checked={ledger} onChange={() => setLedger(!ledger)} />} labelPlacement="start" label="Using Ledger?: " />
                                </div>
                                <button className='font-montserrat utility-btns__purple-btn' style={{ margin: '1rem' }} onClick={() => verifyWallet(signMessage, setAlert, setDisplayModal, publicKey, project.id, ledger, sendTransaction, connection, setDisableSignBtn)} disabled={disableSignBtn}>
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        {disableSignBtn && (<img src={loader} alt={'Loading...'} style={{ height: '1.3rem' }} />)}
                                        <div>Sign Message</div>
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}