import React, { useContext, useEffect, useState } from 'react'
import { StakingContext } from '../../../pages/tools/Staking'
import crypto from 'crypto-browserify'
import { claimTokens } from '../../../javascript/tools/staking'
import { useConnection, useWallet } from '@solana/wallet-adapter-react'
import preloader from './../../../assets/img/utility/preloader.gif'
import { MenuItem, Select } from '@mui/material'
export default function StakingRewards(props) {
    const setAlert = props.setAlert
    const [disableClaimBtn, setDisableClaimBtn] = useState(false)
    const { connection } = useConnection()
    const { signTransaction, publicKey } = useWallet()
    const { stakingRewards, project, displaySignModalState, stakingProject } = useContext(StakingContext)
    const [, setDisplaySignModal] = displaySignModalState
    const [activeClaimToken, setActiveClaimToken] = useState()
    const setStakingRewards = props.setStakingRewards
    useEffect(() => {
        // console.log(stakingProject.tokens)
        setActiveClaimToken(stakingProject.tokens[0])
    }, [stakingProject.tokens])
    if (!stakingRewards || !stakingProject || !publicKey||!activeClaimToken) {
        return (
            <></>
        )
    }

    return (
        <div className='staking-tool__claim-btns-container'>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <button className='font-montserrat staking-tool__claim-btn' onClick={() => claimTokens(activeClaimToken.tokenId, publicKey, project.id, setDisableClaimBtn, setAlert, setStakingRewards, setDisplaySignModal, stakingProject.tokens, connection, signTransaction, stakingProject, stakingRewards)} disabled={disableClaimBtn}>
                    {disableClaimBtn && (<img src={preloader} alt={'Loading'} style={{width: '1.5rem'}}/>)}Claim
                </button>

                <div className='font-montserrat staking-tool__balance-bar'>
                    {Number(+(!stakingRewards.find(x => x.tokenId === activeClaimToken.tokenId) ? 0 : stakingRewards.find(x => x.tokenId === activeClaimToken.tokenId).amount / activeClaimToken.decimals)).toLocaleString('en')} {activeClaimToken.data.tokenSymbol} {!stakingRewards.find(x => x.tokenId === activeClaimToken.tokenId) ? '' : !stakingRewards.find(x => x.tokenId === activeClaimToken.tokenId).boost ? '' : (<><br/>+ {`MisterG AI Boost`}: {stakingRewards.find(x => x.tokenId === activeClaimToken.tokenId).boost.toFixed(2)}x</>)}
                    {!stakingRewards.find(x => x.tokenId === activeClaimToken.tokenId) ? '' : !stakingRewards.find(x => x.tokenId === activeClaimToken.tokenId).boost2 ? '' : (<><br/>+ {`Surfboard Boost`}: {stakingRewards.find(x => x.tokenId === activeClaimToken.tokenId).boost2.toFixed(2)}x</>)}
                </div>
                <Select
                    value={activeClaimToken.tokenId}
                    id='staking-tool__tokens-select-menu'
                    className='font-signika'
                    style={{ height: '40px', background: 'rgb(110, 110, 206)' }}
                    onChange={(event) => setActiveClaimToken(stakingProject.tokens.find(x => x.tokenId === event.target.value))}
                >
                    {stakingProject.tokens.map(x => {
                        return (
                            <MenuItem key={crypto.randomBytes(16).toString('base64')} value={x.tokenId} style={{ color: 'white', margin: '0px !important', fontSize: '1rem', textTransform: 'none' }} className='staking-tool__token-select-option'>
                                <div className='staking-tool__balance-icon'><img src={x.data.tokenLogo} style={{ height: '1.3rem', borderRadius: '100px' }} alt={`${x.data.tokenSymbol} Logo`} /></div>
                            </MenuItem>
                        )
                    })}
                </Select>

            </div>
        </div>
    )
}
