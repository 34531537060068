import { Config } from "../../config"
import { checkVerifiedWallet, showAlert, sleep } from './../utility/utility.js'
import { ComputeBudgetProgram, Connection, LAMPORTS_PER_SOL, PublicKey, SystemProgram, Transaction } from "@solana/web3.js"
import { createAssociatedTokenAccountInstruction, getAssociatedTokenAddress } from "@solana/spl-token"

/**
 * 
 * @param {*} walletAddress 
 * @param {*} project 
 * @param {*} nftsToUnstake 
 * @param {*} signMessage 
 * @param {*} setAlert 
 * @param {*} setStakingProject 
 * @param {*} setDisableUnstakeBtn 
 * @param {*} stakingProject 
 * @param {*} signTransaction 
 * @param {Connection} connection 
 * @param {*} setDisplaySignModal 
 * @returns 
 */
export const unstakeNft = async (walletAddress, project, nftsToUnstake, signMessage, setAlert, setStakingProject, setDisableUnstakeBtn, stakingProject, signTransaction, connection, setDisplaySignModal) => {
    try {
        if (!nftsToUnstake.length) return showAlert(setAlert, 'You need to select NFTs you want to unstake.')
        setDisableUnstakeBtn(true)
        const nonceId = localStorage.getItem('nonceId')
        const nonceSignature = localStorage.getItem('nonceSignature')
        const checkNonce = await checkVerifiedWallet(walletAddress, project.id, nonceId, nonceSignature)
        if (checkNonce.nonce) {
            setDisplaySignModal(true)
            return setDisableUnstakeBtn(false)
        }
        if (checkNonce.error && !checkNonce.nonce) {
            setDisableUnstakeBtn(false)
            return showAlert(setAlert, checkNonce.error)
        }

        if (stakingProject.fees && stakingProject.fees.unstake) {
            let calculatedFees = stakingProject.fees.unstake * nftsToUnstake.length
            if (stakingProject.fees.maxUnstakeFees) {
                if (calculatedFees > stakingProject.fees.maxUnstakeFees) {
                    calculatedFees = stakingProject.fees.maxUnstakeFees
                }
            }
            const transaction = new Transaction()
            .add(ComputeBudgetProgram.setComputeUnitPrice({ microLamports: 1000000 }))
            .add(ComputeBudgetProgram.setComputeUnitLimit({ units: 2_000_000 }))
            .add(SystemProgram.transfer(
                    {
                        fromPubkey: new PublicKey(walletAddress),
                        toPubkey: new PublicKey(stakingProject.feesWallet),
                        lamports: Math.round(calculatedFees * LAMPORTS_PER_SOL)
                    }
                ))
            const blockHash = await connection.getLatestBlockhash();
            transaction.feePayer = new PublicKey(walletAddress);
            transaction.recentBlockhash = blockHash.blockhash;

            const signed = await signTransaction(transaction);
            const sign = await connection.sendRawTransaction(signed.serialize());

            await connection.confirmTransaction(sign, 'finalized');
        }
        const req = await fetch(`${Config.apiURL}/unstake-nfts`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                walletAddress: walletAddress,
                projectId: project.id,
                nonceId: nonceId,
                nonceSignature: nonceSignature,
                nftsToUnstake: nftsToUnstake.map(x => x.mintAddress),
            })
        })
        const res = await req.json()
        if (res.nonce) {
            setDisplaySignModal(true)
            return setDisableUnstakeBtn(false)
        }
        if (res.error && !res.nonce) {
            setDisableUnstakeBtn(false)
            return showAlert(setAlert, res.error)
        }
        if (res.success) {
            setStakingProject((prevState) => ({
                ...prevState,
                unstakedNfts: prevState.unstakedNfts.concat(prevState.stakedNfts.filter(x => nftsToUnstake.find(y => y.mintAddress === x.mintAddress))),
                stakedNfts: prevState.stakedNfts.filter(x => !nftsToUnstake.find(y => y.mintAddress === x.mintAddress)),
                yoursStakedNfts: parseInt(prevState.yoursStakedNfts) - parseInt(nftsToUnstake.length),
                totalStaked: parseInt(prevState.totalStaked) - parseInt(nftsToUnstake.length)
            }))
            setDisableUnstakeBtn(false)
            showAlert(setAlert, `Successfully unstaked your NFTs`, `success`)
            return
        }
        const nonceId2 = localStorage.getItem('nonceId')
        const nonceSignature2 = localStorage.getItem('nonceSignature')
        const req2 = await fetch(`${Config.apiURL}/unstake-nfts`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                walletAddress: walletAddress,
                projectId: project.id,
                nonceId: nonceId2,
                nonceSignature: nonceSignature2,
                nftsToUnstake: nftsToUnstake.map(x => x.mintAddress),
            })
        })
        const res2 = await req2.json()
        if (res2.error) {
            setDisableUnstakeBtn(false)
            return showAlert(setAlert, res2.error)
        } else if (res2.success) {
            setDisableUnstakeBtn(false)
            setStakingProject((prevState) => ({
                ...prevState,
                unstakedNfts: prevState.unstakedNfts.concat(prevState.stakedNfts.filter(x => nftsToUnstake.find(y => y.mintAddress === x.mintAddress))),
                stakedNfts: prevState.stakedNfts.filter(x => !nftsToUnstake.find(y => y.mintAddress === x.mintAddress)),
                yoursStakedNfts: parseInt(prevState.yoursStakedNfts) - parseInt(nftsToUnstake.length),
                totalStaked: parseInt(prevState.totalStaked) - parseInt(nftsToUnstake.length)
            }))
            showAlert(setAlert, `Successfully unstaked your NFTs`, `success`)
        }
    } catch (err) {
        console.log(err)
        setDisableUnstakeBtn(false)
        return showAlert(setAlert, `An unknown error occured`)
    }
}

export const stakeNfts = async (walletAddress, project, nftsToStake, planId, signMessage, setAlert, closeStakeNFT, setDisableStakeBtn, stakingProject, signTransaction, connection, setDisplaySignModal) => {
    try {
        setDisableStakeBtn(true)
        const nonceId = localStorage.getItem('nonceId')
        const nonceSignature = localStorage.getItem('nonceSignature')

        const checkNonce = await checkVerifiedWallet(walletAddress, project.id, nonceId, nonceSignature)
        if (checkNonce.nonce) {
            setDisplaySignModal(true)
            return setDisableStakeBtn(false)
        }

        if (checkNonce.error && !checkNonce.nonce) {
            setDisableStakeBtn(false)
            return showAlert(setAlert, checkNonce.error)
        }
        const reqCheckStake = await fetch(`${Config.apiURL}/check-stake-nfts`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                walletAddress: walletAddress,
                projectId: project.id,
                nonceId: nonceId,
                nonceSignature: nonceSignature,
                nftsToStake: nftsToStake.map(x => x.mintAddress),
                planId: planId
            })
        })
        const resCheckStake = await reqCheckStake.json()
        if (resCheckStake.nonce) {
            setDisplaySignModal(true)
            return setDisableStakeBtn(false)
        }
        if (!resCheckStake.status) {
            setDisableStakeBtn(false)
            return showAlert(setAlert, resCheckStake.error)
        }

        if (stakingProject?.fees?.stake) {
            let calculatedFees = stakingProject.fees.stake * nftsToStake.length
            if (stakingProject.fees.maxStakeFees) {
                if (calculatedFees > stakingProject.fees.maxStakeFees) {
                    calculatedFees = stakingProject.fees.maxStakeFees
                }
            }

            const transaction = new Transaction()
                .add(ComputeBudgetProgram.setComputeUnitPrice({ microLamports: 1000000 }))
                .add(ComputeBudgetProgram.setComputeUnitLimit({ units: 2_000_000 }))
                .add(SystemProgram.transfer(
                    {
                        fromPubkey: new PublicKey(walletAddress),
                        toPubkey: new PublicKey(stakingProject.feesWallet),
                        lamports: Math.round(calculatedFees * LAMPORTS_PER_SOL)
                    }
                ))
            const blockHash = await connection.getLatestBlockhash();
            transaction.feePayer = new PublicKey(walletAddress);
            transaction.recentBlockhash = blockHash.blockhash;

            const signed = await signTransaction(transaction);
            const sign = await connection.sendRawTransaction(signed.serialize());

            await connection.confirmTransaction(sign, 'finalized');
        } else if (stakingProject?.feesSplit?.stake) {
            const transaction = new Transaction()
            .add(ComputeBudgetProgram.setComputeUnitPrice({ microLamports: 1000000 }))
            .add(ComputeBudgetProgram.setComputeUnitLimit({ units: 2_000_000 }))

            for (const fees of stakingProject.feesSplit.stake) {
                let calculatedFees = fees.amount * nftsToStake.length
                if (fees.maxFees) {
                    if (calculatedFees > fees.maxFees) {
                        calculatedFees = fees.maxFees
                    }
                }

                transaction.add(SystemProgram.transfer(
                    {
                        fromPubkey: new PublicKey(walletAddress),
                        toPubkey: new PublicKey(fees.walletAddress),
                        lamports: Math.round(calculatedFees * LAMPORTS_PER_SOL)
                    }
                ))
            }
            const blockHash = await connection.getLatestBlockhash();
            transaction.feePayer = new PublicKey(walletAddress);
            transaction.recentBlockhash = blockHash.blockhash;

            const signed = await signTransaction(transaction);
            const sign = await connection.sendRawTransaction(signed.serialize());

            await connection.confirmTransaction(sign, 'finalized');
        }

        const req = await fetch(`${Config.apiURL}/stake-nfts`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                walletAddress: walletAddress,
                projectId: project.id,
                nonceId: nonceId,
                nonceSignature: nonceSignature,
                nftsToStake: nftsToStake.map(x => x.mintAddress),
                planId: planId
            })
        })
        const res = await req.json()
        if (res.nonce) {
            setDisplaySignModal(true)
            return setDisableStakeBtn(false)
        }

        if (res.error && !res.nonce) {
            setDisableStakeBtn(false)
            return showAlert(setAlert, res.error)
        }
        if (res.success) {
            setDisableStakeBtn(false)
            await closeStakeNFT()
            showAlert(setAlert, `Successfully staked your NFTs`, `success`)
            return
        }
        const nonceId2 = localStorage.getItem('nonceId')
        const nonceSignature2 = localStorage.getItem('nonceSignature')
        const req2 = await fetch(`${Config.apiURL}/stake-nfts`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                walletAddress: walletAddress,
                projectId: project.id,
                nonceId: nonceId2,
                nonceSignature: nonceSignature2,
                nftsToStake: nftsToStake.map(x => x.mintAddress),
                planId: planId
            })
        })
        const res2 = await req2.json()
        if (res2.error) {
            setDisableStakeBtn(false)
            return showAlert(setAlert, res2.error)
        } else if (res2.success) {
            setDisableStakeBtn(false)
            await closeStakeNFT()
            showAlert(setAlert, `Successfully staked your NFTs`, `success`)
        }
    } catch (err) {
        console.log(err)
        setDisableStakeBtn(false)
        return showAlert(setAlert, `ERROR ${err.message || 'An unknown error occured'}`)
    }
}

export const setProjectData = async (params, setPageError, setProject) => {
    try {
        const id = params.id
        const projectInfo = await fetch(`${Config.apiURL}/get-project`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                projectId: id
            })
        })
        const projectInfoJson = await projectInfo.json()
        if (projectInfoJson.error) {
            setPageError(true)
        } else {
            setProject(projectInfoJson)
        }
    } catch {
        setPageError(true)
    }
}

export const setStakingProjectInfo = async (params, setPageError, walletAddress, setStakingProject, setStakingRewards) => {
    try {
        const id = params
        const payload = { projectId: id }
        if (walletAddress) payload.walletAddress = walletAddress
        if (payload.walletAddress) {
            await fetch(`${Config.apiURL}/update-staking-rewards`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(payload)
            })
        }
        const projectInfo = await fetch(`${Config.apiURL}/get-staking-info`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(payload)
        })
        const projectInfoJson = await projectInfo.json()
        if (projectInfoJson.error) {
            setPageError(true)
        } else {
            setStakingProject(projectInfoJson)
        }
        if (walletAddress) {
            const stakingRewards = await fetch(`${Config.apiURL}/get-staking-rewards`, {
                method: 'POST',
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(payload)
            })
            const stakingRewardsJson = await stakingRewards.json()
            if (stakingRewardsJson.rewards) {
                setStakingRewards(stakingRewardsJson.rewards)
            } else {
                setStakingRewards([])
            }
        } else {
            setStakingRewards([])
        }
    } catch {
        setPageError(true)
    }
}
export const claimTokens = async (tokenId, publicKey, projectId, setDisableClaimBtn, setAlert, setStakingRewards, setDisplaySignModal, tokens, connection, signTransaction, stakingProject, projectAccount) => {
    try {
        setDisableClaimBtn(true)
        const nonceId = localStorage.getItem('nonceId')
        const nonceSignature = localStorage.getItem('nonceSignature')
        const checkNonce = await checkVerifiedWallet(publicKey, projectId, nonceId, nonceSignature)
        const token = tokens.find(x => x.tokenId === tokenId)

        if (!projectAccount?.length) {
            setDisableClaimBtn(false)
            return showAlert(setAlert, 'You dont have any tokens to claim!')
        }
        const tokenInProjectAccount = projectAccount?.find(z => z.tokenId === tokenId)
        if (!tokenInProjectAccount || !tokenInProjectAccount?.amount) {
            setDisableClaimBtn(false)
            return showAlert(setAlert, 'You dont have any tokens to claim!')
        }

        const tx = new Transaction()

        if (checkNonce.nonce) {
            setDisplaySignModal(true)
            return setDisableClaimBtn(false)
        }
        if (checkNonce.error && !checkNonce.nonce) {
            setDisableClaimBtn(false)
            return showAlert(setAlert, checkNonce.error)
        }


        if (token.tokenId !== 'SOLANA_TOKEN') {
            const mintToken = new PublicKey(token.tokenAddress)
            const pub = new PublicKey(publicKey)
            if (!mintToken) {
                showAlert(setAlert, `An unknown error occured`)
                return setDisableClaimBtn(false)
            }
            const associatedTokenTo = await getAssociatedTokenAddress(
                mintToken,
                pub
            );
            if (!(await connection.getAccountInfo(associatedTokenTo))) {
                tx.add(
                    createAssociatedTokenAccountInstruction(
                        pub,
                        associatedTokenTo,
                        pub,
                        mintToken
                    )
                )
            }
        }

        if (stakingProject.fees?.claim || stakingProject.feesSplit?.claim) {
            if (stakingProject.fees?.claim) {
                let fees = stakingProject.fees.claim
                if (stakingProject.fees?.claimPerNft) {
                    fees = fees * (projectAccount.data?.numberOfClaimFees || 1)
                }
                tx.add(ComputeBudgetProgram.setComputeUnitPrice({ microLamports: 1000000 }))
                tx.add(ComputeBudgetProgram.setComputeUnitLimit({ units: 2_000_000 }))
                tx.add(
                    SystemProgram.transfer({
                        toPubkey: new PublicKey(stakingProject.feesWallet),
                        fromPubkey: publicKey,
                        lamports: fees * LAMPORTS_PER_SOL
                    })
                )

            } else if (stakingProject.feesSplit?.claim) {
                tx.add(ComputeBudgetProgram.setComputeUnitPrice({ microLamports: 1000000 }))
                tx.add(ComputeBudgetProgram.setComputeUnitLimit({ units: 2_000_000 }))
                for (let fees of stakingProject.feesSplit.claim) {
                    let feesUpdated = fees.amount
                    if (stakingProject.fees?.claimPerNft) {
                        feesUpdated = fees.amount * (projectAccount.data?.numberOfClaimFees || 1)
                    }

                    tx.add(
                        SystemProgram.transfer({
                            toPubkey: new PublicKey(fees.walletAddress),
                            fromPubkey: publicKey,
                            lamports: feesUpdated * LAMPORTS_PER_SOL
                        })
                    )

                }

            }
        }
        if (tx.instructions?.length) {
            const blockHash = await connection.getLatestBlockhash();
            tx.feePayer = new PublicKey(publicKey);
            tx.recentBlockhash = blockHash.blockhash;

            const signed = await signTransaction(tx);
            const sign = await connection.sendRawTransaction(signed.serialize());

            await connection.confirmTransaction(sign, 'finalized');
        }

        async function sendClaimReq() {
            const req = await fetch(`${Config.apiURL}/claim-stake`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    tokenId: tokenId,
                    walletAddress: publicKey,
                    projectId: projectId
                })
            })
            const res = await req.json()

            if (res.nonce) {
                setDisplaySignModal(true)
                return setDisableClaimBtn(false)
            }
            if (res.error) {
                if (res.error === 'Token Account not found') {
                    await sleep(3000)
                    return sendClaimReq()
                } else {
                    for (let i = 0; i < 10; i++) {
                        await sleep(3000)
                        return sendClaimReq()
                    }
                }
                setDisableClaimBtn(false)
                return showAlert(setAlert, res.error)
            } else {
                setStakingRewards((prevState) => {
                    if (prevState.find(x => x.tokenId === tokenId)) {
                        prevState.find(x => x.tokenId === tokenId).amount = 0
                    }
                    return prevState
                })
                setDisableClaimBtn(false)
                return showAlert(setAlert, res.success, 'success')
            }
        }
        sendClaimReq()
    } catch (err) {
        console.log(err)
        setDisableClaimBtn(false)
        return showAlert(setAlert, `An unknown error occured`)
    }
}