import { Button } from '@mui/material'
import React, { useContext, useState } from 'react'
import { GiHamburgerMenu } from 'react-icons/gi'
import { motion } from 'framer-motion'
import { StakingContext } from '../../pages/tools/Staking';
import ConnectButton from '../utility/Tools/ConnectButton';

export default function HasukiDataTableBtn() {
    const [isOpen, setOpen] = useState(false);
    const { project, setWalletAddress, walletAddress } = useContext(StakingContext)
    const toggleOpen = () => setOpen(!isOpen)

    const variants = {
        open: {
            opacity: 1,
            height: 'fit-content',
            y: '-5px',
        },
        closed: {
            opacity: 0,
            height: '0px',
            y: "-100%"
        }
    }

    return (
        <>

            <div className='staking-tool__project-info-tab !p-[30px]'>
                <div className='staking-tool__project-info !p-0'>
                    <div className='flex justify-between items-center gap-[1rem]'>
                        <img src={project.logo} className='staking-tool__project-img' alt="Project" />

                    </div>

                    <div className='staking-tool__connect-btn flex items-center justify-center gap-[1rem]'>
                        <a href='https://hasuki-staking-datable.vercel.app/' className='hidden lg:block'>
                            <Button style={{
                                fontSize: '1.3rem',
                                margin: '1rem auto',
                                // background: 'rgb(0, 123, 214)',
                                color: 'white',
                                padding: '0px 1.3rem',
                                fontFamily: "Share Tech"
                            }}>
                                Datatable
                            </Button>
                        </a>
                        <div onClick={toggleOpen} className='block lg:hidden'>
                            <GiHamburgerMenu className='text-[2rem] text-white cursor-pointer' />
                        </div>
                        <div style={{ right: "20px" }}>
                            <ConnectButton project={project} walletAddress={[walletAddress, setWalletAddress]} />
                        </div>
                    </div>
                </div>
                <motion.div
                    animate={isOpen ? "open" : "closed"}
                    variants={variants}
                    style={{ overflow: 'hidden' }}

                >
                    <div className='flex justify-center items-center gap-[1rem] lg:hidden'>
                        <a href='https://hasuki-staking-datable.vercel.app/'>
                            <Button style={{
                                fontSize: '1.3rem',
                                margin: '1rem auto',
                                background: 'rgb(0, 123, 214)',
                                color: 'white',
                                padding: '0.2rem 2rem',
                                textTransform: 'none',
                                fontFamily: "Share Tech"
                            }}>
                                Datatable
                            </Button>
                        </a>
                    </div>
                </motion.div>
            </div>



        </>
    )
}
