import React, { useMemo } from 'react'
import {
    AlphaWalletAdapter,
    PhantomWalletAdapter,
    SaifuWalletAdapter,
    SolflareWalletAdapter,
    TorusWalletAdapter
} from '@solana/wallet-adapter-wallets';
import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react';
import { WalletModalProvider } from '@solana/wallet-adapter-react-ui';
import { Config } from '../../../config';

require('@solana/wallet-adapter-react-ui/styles.css');

export default function SolanaWalletProvider({ children }) {
    const network = Config.SolanaCluster
    const endpoint = useMemo(() => (network), [network]);

    const wallets = useMemo(() => [
        new PhantomWalletAdapter(),
        new AlphaWalletAdapter(),
        new SaifuWalletAdapter(),
        new SolflareWalletAdapter({ network }),
        new TorusWalletAdapter(),
    ], [network])
    return (
        <ConnectionProvider endpoint={endpoint} config={{confirmTransactionInitialTimeout: 300000}}>
            <WalletProvider wallets={wallets} autoConnect>
                <WalletModalProvider>{children}</WalletModalProvider>
            </WalletProvider>
        </ConnectionProvider>
    )
}
