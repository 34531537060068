import { Button, FormControlLabel, InputLabel, MenuItem, Radio, RadioGroup, Select } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react'
import { CSSTransition } from 'react-transition-group';
import { StakingContext } from '../../../pages/tools/Staking'
import crypto from 'crypto-browserify'
import { stakeNfts } from '../../../javascript/tools/staking';
import preloader from './../../../assets/img/utility/preloader.gif'
import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import { LazyLoadComponent, LazyLoadImage } from 'react-lazy-load-image-component';
export default function StakeNFTs({ setAlert, setStakingProject }) {
  const { stakingProject, project, displaySignModalState } = useContext(StakingContext)
  const [, setDisplaySignModal] = displaySignModalState
  const [unstakedNfts, setUnstakedNfts] = useState()
  const { signMessage, signTransaction, publicKey } = useWallet()
  const [disableStakeBtn, setDisableStakeBtn] = useState(false)
  const { connection } = useConnection()
  const [showNFTList, setShowNFTList] = useState(true);
  const [showNFTStakeForm, setShowNFTStakeForm] = useState(false);
  const [nftsToStake, setNftsToStake] = useState([])
  const [stakeOptionsChosen, setStakeOptionsChosen] = useState({ locked: 'locked', interval: '', currency: '' })
  const [stakeOptions, setStakeOptions] = useState()
  const [stakePlanChosen, setStakePlanChosen] = useState({})
  useEffect(() => {
    if (!stakingProject?.stakingOptions?.find(z => z.locked)) {
      setStakeOptionsChosen((prev) => {
        return {
          ...prev,
          interval: 1,
          locked: 'unlocked'
        }
      })
    }
  }, [stakingProject])
  useEffect(() => {
    if (stakingProject.nftsOfCollection) {
      const nftsOfCollecton = stakingProject.nftsOfCollection
      const stakedNfts = stakingProject.stakedNfts
      const unstakedNfts = nftsOfCollecton.filter(x => !stakedNfts.find(y => y.mintAddress === x.mintAddress))
      setUnstakedNfts(unstakedNfts)
      const intervals = []
      const rewardCurrencies = []
      const lockedUnlockedOptions = []
      const locked = stakingProject.stakingOptions.find(x => x.locked === true)
      const unlocked = stakingProject.stakingOptions.find(x => x.locked === false)
      if (locked) lockedUnlockedOptions.push('locked')
      if (unlocked) lockedUnlockedOptions.push('unlocked')
      if (!locked && stakeOptionsChosen.locked === 'locked') setStakeOptions((prevstate) => ({ ...prevstate, locked: 'unlocked' }))

      let afterLockCheckOptions = stakingProject.stakingOptions.filter(y => y.locked === (stakeOptionsChosen.locked === 'locked' ? true : false))
      for (const option of afterLockCheckOptions) {
        const interval = Math.round(option.interval)
        if (!intervals.includes(interval)) {
          intervals.push(interval)
        }
      }
      let afterCheckInterval = []
      if (stakeOptionsChosen.interval) {
        afterCheckInterval = afterLockCheckOptions.filter(y => y.interval === stakeOptionsChosen.interval)
      }
      for (const option of afterCheckInterval) {
        const rewardCurrency = option.rewards
        for (const reward of rewardCurrency) {
          if (!rewardCurrencies.includes(reward.currency)) {
            rewardCurrencies.push(reward.currency)
          }
        }
      }

      if (!intervals.includes(stakeOptionsChosen.interval)) {
        if (stakeOptionsChosen.interval !== '') {
          setStakeOptionsChosen((prevState) => ({ ...prevState, interval: '' }))
        }
      }

      if (!rewardCurrencies.includes(stakeOptionsChosen.currency)) {
        if (stakeOptionsChosen.currency !== '') {
          setStakeOptionsChosen((prevState) => ({ ...prevState, currency: '' }))
        }
      }
      const stakePlanOption = stakingProject.stakingOptions.find(x => x.locked === (stakeOptionsChosen.locked === 'locked' ? true : false) && x.interval && x.interval === stakeOptionsChosen.interval)
      if (stakePlanOption) {
        const stakePlanReward = stakePlanOption.rewards.find(y => y.currency === (project.id === 'bullyz' ? '$ETA' : stakeOptionsChosen.currency))
        if (stakePlanReward) {
          setStakePlanChosen(stakePlanReward)
        } else {
          if (stakePlanChosen) setStakePlanChosen({})
        }
      } else {
        if (stakePlanChosen) setStakePlanChosen({})
      }
      if (rewardCurrencies.length === 1 && !stakeOptionsChosen?.currency) setStakeOptionsChosen((prevState) => ({ ...prevState, currency: rewardCurrencies[0] }))
      setStakeOptions({ intervals, rewardCurrencies, lockedUnlockedOptions })
    }
    // eslint-disable-next-line
  }, [stakingProject, stakeOptionsChosen])

  const onstakingOptionLockedChange = (event) => {
    if (event.target.value === 'unlocked') {
      setStakeOptionsChosen((prevState) => ({ ...prevState, locked: event.target.value, interval: 1 }))
    } else {
      setStakeOptionsChosen((prevState) => ({ ...prevState, locked: event.target.value, interval: "" }))
    }
  }
  const closeStakeForm = async () => {
    setShowNFTStakeForm(false)
    setStakingProject((prevState) => ({
      ...prevState,
      stakedNfts: prevState.stakedNfts.concat(prevState.nftsOfCollection.filter(x => nftsToStake.find(y => y.mintAddress === x.mintAddress))),
      unstakedNfts: prevState.unstakedNfts.filter(x => !nftsToStake.find(y => y.mintAddress === x.mintAddress)),
      yoursStakedNfts: parseInt(prevState.yoursStakedNfts) + parseInt(nftsToStake.length),
      totalStaked: parseInt(prevState.totalStaked) + parseInt(nftsToStake.length)
    }))
  }
  const openStakeNFTForm = (event) => {
    event.preventDefault()
    let answers = document.getElementById('staking-tool__stake-form')
    const nftsChosenArray = []
    for (var i = 0; i < answers.length; i++) {
      if (answers.elements[i].type !== 'submit') {
        nftsChosenArray.push({ mint: answers.elements[i].id, checked: answers.elements[i].checked });
      }
    }
    let nftsToStakeArr = nftsChosenArray.filter(x => x.checked === true)
    if (!nftsToStakeArr.length) {
      setNftsToStake([])
    } else {
      const nftsToStake = unstakedNfts.filter(x => nftsToStakeArr.find(y => y.mint === x.mintAddress))
      setNftsToStake(nftsToStake)
      setShowNFTStakeForm(true)
    }
  }

  const getRateFromPlan = (plan, noAPR = false) => {
    if (plan.currency) {
      let rate = `${plan.amount} ${plan.currency}`
      if (plan.type === 'APR') rate = `${plan.amount}% APR ${noAPR ? '' : `(${plan.amount}% of floor price/year)`}`
      if (stakeOptionsChosen.locked === 'unlocked') rate += ' per day'
      return rate
    } else {
      return ""
    }
  }
  if (!publicKey) {
    return (<div className='staking-tool__red-border-container staking-tool__red-border-container-align-center'>
      <div className='font-share-tech' style={{ color: 'white', display: 'flex', alignItems: 'center', textAlign: 'center', maxWidth: '80%', fontSize: '1.5rem', paddingLeft: '25px', paddingRight: '25px', paddingTop: '15px', paddingBottom: '15px', height: 'fit-content' }}>
        You have not connected your wallet
      </div>
    </div>
    )
  }

  if (!stakingProject.nftsOfCollection) {
    return (
      <div className='staking-tool__red-border-container staking-tool__red-border-container-align-center'>
        <div className='font-signika' style={{ borderRadius: '15px', color: 'white', display: 'flex', alignItems: 'center', textAlign: 'center', maxWidth: '80vw', fontSize: '2.5rem', fontWeight: '700', paddingLeft: '25px', paddingRight: '25px', paddingTop: '15px', paddingBottom: '15px', height: 'fit-content' }}>
          <LazyLoadImage src={preloader} style={{ width: '150px', maxWidth: '60%' }} alt='preloader' />
        </div>
      </div>
    )
  }

  if (!unstakedNfts) return (<></>)

  if (!unstakedNfts.length || !stakeOptions) {
    return (
      <div className='staking-tool__red-border-container staking-tool__red-border-container-align-center'>
        <div className='font-share-tech' style={{ color: 'white', display: 'flex', alignItems: 'center', textAlign: 'center', maxWidth: '80%', fontSize: '1.5rem', paddingLeft: '25px', paddingRight: '25px', paddingTop: '15px', paddingBottom: '15px', height: 'fit-content' }}>
          You do not have any {project.name} NFT to Stake
        </div>
      </div>
    )
  }
  return (
    <div style={{ width: '100%' }}>
      {showNFTList && (
        <>
          <div className='staking-tool__red-border-container'>
            <form id='staking-tool__stake-form' onSubmit={(event) => event.preventDefault()}>
              {unstakedNfts.filter(y => !y.locked).length ? (<SelectAllBtn />) : ''}
              <div className='staking-tool__stake-unstake-flex-container'>
                <LazyLoadComponent>
                  {unstakedNfts.filter(y => !y.locked).map(x => {
                    return (
                      <div key={x.mintAddress} style={{ background: 'linear-gradient(to bottom right, #835bffd6, #44206dc7)', margin: '0.2rem', }}>
                        <input type={'checkbox'} id={x.mintAddress} className='staking-tool__stake-unstake-nft-input-checkbox' />
                        <label htmlFor={x.mintAddress} className='staking-tool__stake-unstake-nft-label'>
                          <div className='staking-tool__stake-unstake-nft'>
                            <LazyLoadImage src={x.image} style={{ width: '100%' }} alt={(x.name || `${project.id} nft`)} />
                          </div>
                          <div style={{ display: 'block', textAlign: 'center', color: 'white', margin: '0.5rem' }} className='font-signika'>
                            {x.name && (<div className='staking-tool__stake-unstake-nft-name'>{x.name}</div>)}
                          </div>

                        </label>
                      </div>
                    )
                  })}
                </LazyLoadComponent>
                {unstakedNfts.filter(y => y.locked).map(x => {
                  return (
                    <div key={x.mintAddress} style={{ background: 'linear-gradient(to bottom right, #835bffd6, #44206dc7)' }}>
                      <a href={`https://royalty.etakit.in/${project.id}`} target={"_blank"} rel={"noreferrer"}>
                        <div className='staking-tool__stake-unstake-locked-nft'>
                          <div className='staking-tool__stake-unstake-nft' style={{ background: `url(${x.image})`, backgroundSize: 'cover' }}>
                            <div style={{ width: '100%', borderRadius: '10px', height: '100%', backgroundColor: '#000000ba', display: 'flex', alignItems: 'center' }}>
                              <LazyLoadImage src='https://cdn.discordapp.com/emojis/808369470866849852.webp?size=128&quality=lossless' style={{ height: '50%', display: 'block', margin: 'auto' }} alt={`${x.name || `${project.id} NFT`} locked`} />
                            </div>
                          </div>
                        </div>
                      </a>
                      <div style={{ display: 'block', textAlign: 'center', color: 'white', margin: '0.5rem' }} className='font-signika'>
                        {x.name && (<div className='staking-tool__stake-unstake-nft-name'>{x.name}</div>)}
                      </div>
                    </div>
                  )
                })}
              </div>
            </form>
          </div>
          <button className='staking-tool_stake-unstake-form-submit-btn' onClick={(event) => openStakeNFTForm(event)} style={{ margin: '0.5rem auto' }}>Stake</button>
        </>

      )}
      <CSSTransition
        in={showNFTStakeForm}
        timeout={300}
        classNames="alert"
        unmountOnExit
        onEnter={() => setShowNFTList(false)}
        onExited={() => setShowNFTList(true)}
      >
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          padding: '0px',
          // border: '3px solid #c15959',
          overflowY: 'scroll',
          width: '100%',
          // height: '400px',
          marginBottom: '1rem',
          // maxHeight: '50vh',
          backdropFilter: 'blur(3px)'
        }}>

          <div style={{ padding: '10px', backgroundColor: '#3b38ad', width: '500px', maxWidth: '80vw', height: 'fit-content' }} className='staked-nfts__staking-bg'>
            <Button
              variant="primary"
              style={{ fontFamily: 'Signika Negative', display: 'block', marginLeft: '90%', fontSize: '0.8rem', padding: '0px', width: '30px', height: '30px', minWidth: '0px', color: 'white', borderRadius: '100px', background: '#c62626', fontWeight: 'bold' }}
              onClick={() => setShowNFTStakeForm(false)}
              disabled={disableStakeBtn}
            >
              ✖
            </Button>

            <form>
              <div style={{ display: 'flex', flexFlow: 'row wrap', justifyContent: 'center' }}>
                <div style={{ background: 'rgb(127, 127, 237)', padding: '10px', margin: '10px' }} className='stake-nft__staking__options'>
                  <RadioGroup
                    value={stakeOptionsChosen.locked}
                    onChange={(event) => onstakingOptionLockedChange(event)}
                  >
                    <FormControlLabel value="locked" className='staking-tool__locked-unlocked-radio-btn' control={<Radio />} label="Locked" style={{ display: `${stakeOptions.lockedUnlockedOptions.includes('locked') ? 'block' : 'none'}` }} />
                    <FormControlLabel value="unlocked" className='staking-tool__locked-unlocked-radio-btn' control={<Radio />} label="Unlocked" style={{ display: `${stakeOptions.lockedUnlockedOptions.includes('unlocked') ? 'block' : 'none'}` }} />
                  </RadioGroup>
                </div>

                {stakeOptionsChosen.locked !== 'unlocked' && <div style={{ background: 'rgb(127, 127, 237)', padding: '10px', margin: '10px' }} className='stake-nft__staking__options'>
                  <InputLabel variant="standard" htmlFor="staking-tool__stake-nft-options-interval" className='font-baloo' style={{ fontSize: '1rem', color: 'white', textAlign: 'center', fontFamily: `'Share Tech'`, textTransform: 'uppercase' }}>
                    Time Interval
                  </InputLabel>
                  <Select
                    value={stakeOptionsChosen.interval}
                    id='staking-tool__stake-nft-options-interval'
                    style={{ fontSize: '1rem', color: 'white', margin: 'auto', backgroundColor: 'rgb(110 110 206)', opacity: `${!stakeOptionsChosen.locked ? '70%' : '100%'}`, width: '100%' }}
                    className='font-share-tech'
                    onChange={(event) => setStakeOptionsChosen((prevState) => ({ ...prevState, interval: !event.target.value ? '' : event.target.value }))}
                  >
                    {stakeOptions.intervals.map(x => {
                      return (
                        <MenuItem key={crypto.randomBytes(16).toString('base64')} value={x} style={{ backgroundColor: 'rgb(110 110 206)', color: 'white', margin: '0px', width: '100%' }} className='font-share-tech staking-tool__stake-nft-menu-item' disabled={!stakeOptionsChosen.locked ? true : false}>{`${convertDuration(x)}`}</MenuItem>
                      )
                    })}
                  </Select>
                </div>}

                <div style={{ background: 'rgb(127, 127, 237)', padding: '10px', margin: '10px', opacity: `${!stakeOptionsChosen.interval ? '70%' : '100%'}` }} className='staking-tool__stake-nft-options__currency-input stake-nft__staking__options'>
                  <InputLabel variant="standard" htmlFor="uncontrolled-native" style={{ fontSize: '1rem', color: 'white', textAlign: 'center', fontFamily: `'Share Tech'`, textTransform: 'uppercase' }}>
                    Token
                  </InputLabel>
                  <Select
                    disabled={!stakeOptionsChosen.interval ? true : false}
                    value={stakeOptionsChosen.currency}
                    id='staking-tool__stake-nft-options-reward-currency'
                    style={{ fontSize: '1rem', color: 'white', margin: 'auto', backgroundColor: 'rgb(110 110 206)', width: '100%' }}
                    className='font-signika'
                    onChange={(event) => setStakeOptionsChosen((prevState) => ({ ...prevState, currency: event.target.value }))}
                  >
                    {stakeOptions.rewardCurrencies.map(x => {
                      return (
                        <MenuItem key={crypto.randomBytes(16).toString('base64')} value={x} style={{ backgroundColor: 'rgb(110 110 206)', color: 'white', margin: '0px', width: '100%' }} className='font-share-tech staking-tool__stake-nft-menu-item' disabled={!stakeOptionsChosen.interval ? true : false}>{x}</MenuItem>
                      )
                    })}
                  </Select>
                </div>
              </div>
              {stakePlanChosen.planId && (
                <div style={{ background: 'rgb(127, 127, 237)', padding: '10px 1rem', margin: '1rem 2rem' }} className='staking-tool__stake-nft-options__reward-rate-show'>
                  <span style={{ fontSize: '1rem', color: 'white', fontWeight: '700', textTransform: 'uppercase', textAlign: 'center', fontFamily: `'Share Tech'` }}>Reward {'(Per NFT)'}: </span><span style={{ fontSize: '1rem', color: 'white', textAlign: 'center', fontFamily: `'Signika Negative'` }}>{getRateFromPlan(stakePlanChosen)}</span>
                </div>
              )}

              <div style={{ display: 'flex', flexFlow: 'row wrap', justifyContent: 'center', alignContent: 'center' }}>
                {nftsToStake.map(x => {
                  let planInStakeOption = undefined
                  if (x.stakingOptions) {
                    planInStakeOption = x.stakingOptions.find(z => z.planId === stakePlanChosen.planId)
                  }
                  return (
                    <div key={x.mintAddress}>
                      <div className='staking-tool__staking-nft'>
                        <LazyLoadImage src={x.image} style={{ width: '100%' }} alt={x.name} />
                      </div>
                      <div style={{ display: 'block', margin: 'auto', textAlign: 'center', color: 'white' }} className='font-signika'>
                        {planInStakeOption ? getRateFromPlan(planInStakeOption, true) : ''}
                      </div>
                    </div>
                  )
                })}
              </div>

              {stakePlanChosen.planId && (
                <div style={{ display: 'flex', marginTop: '1rem 0px', justifyContent: 'center', alignItems: 'center' }}><Button style={{
                  fontSize: '1.3rem',
                  margin: '1rem auto',
                  background: '#007bd6',
                  color: 'white',
                  padding: '0px 1.3rem',
                  fontFamily: 'Share Tech'
                }} onClick={() => stakeNfts(publicKey, project, nftsToStake, stakePlanChosen.planId, signMessage, setAlert, closeStakeForm, setDisableStakeBtn, stakingProject, signTransaction, connection, setDisplaySignModal)} disabled={disableStakeBtn}>Stake</Button></div>
              )}
            </form>
          </div>
        </div>
      </CSSTransition >
    </div >

  )
}


const SelectAllBtn = () => {
  const selectAll = async () => {
    let inputs = []
    const form = document.getElementById('staking-tool__stake-form')
    for (var i = 0; i < form.length; i++) {
      inputs.push(form.elements[i])
    }
    let unchecked = inputs.filter(x => x.type === 'checkbox' && !x.checked)
    if (unchecked.length) {
      unchecked.forEach(x => {
        if (x.type === 'checkbox') {
          x.checked = true
        }
      })
    } else {
      inputs.forEach(x => {
        x.checked = false
      })
    }
  }
  return (
    <Button onClick={selectAll} style={{
      backgroundColor: 'rgb(127, 127, 237)',
      border: '4px solid rgb(84 84 166)',
      color: 'white',
      margin: 'auto',
      display: 'block'
    }}>
      Select All
    </Button>
  )
}

function convertDuration(days) {
  const years = Math.floor(days / 365);
  const months = Math.floor((days % 365) / 30);
  const remainingDays = days % 30;

  let duration = '';
  if (years > 0) {
    duration += years + (years === 1 ? ' year ' : ' years ');
  }
  if (months > 0) {
    duration += months + (months === 1 ? ' month ' : ' months ');
  }
  if (remainingDays > 0) {
    duration += remainingDays + (remainingDays === 1 ? ' day ' : ' days ');
  }

  return duration.trim();
}
