import React from 'react'
import xIcon from './../../assets/custom/kingpins/xIcon.png'
import discordIcon from './../../assets/custom/kingpins/discordIcon.png'
import websiteIcon from './../../assets/custom/kingpins/websiteIcon.png'

export default function KingpinsSocials() {
  return (
    <div className='justify-center items-center gap-[0.5rem] hidden sm:flex'>
      <a href='https://twitter.com/kngpnsnft' target='_blank'>
        <img src={xIcon} alt='x' className='w-[2.2rem]' />
      </a>
      <a href='https://discord.gg/kingpins' target='_blank'>
        <img src={discordIcon} alt='discord' className='w-[2.2rem]' />
      </a>

      <a href='https://www.kngpns.com/' target='_blank'>
        <img src={websiteIcon} alt='website' className='w-[2.2rem]' />
      </a>
    </div>
  )
}

