import crypto from 'crypto-browserify'
import { Config } from '../../config'
import bs58 from 'bs58'
import { PublicKey, Transaction, TransactionInstruction } from '@solana/web3.js'
export const updateStateObject = (setState, objectKey, value) => {
    setState((prevState) => ({
        ...prevState,
        [`${objectKey}`]: value
    }))
}


export const checkVerifiedWallet = async (walletAddress, projectId, nonceId, signature) => {
    try {
        const nonceReq = await fetch(`${Config.apiURL}/verify-nonce`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                walletAddress: walletAddress,
                projectId: projectId,
                nonceId: nonceId,
                signature: signature
            })
        })
        const nonce = await nonceReq.json()
        return nonce
    } catch {
        return { error: 'An unknown error occured' }
    }
}
export function nFormatter(num) {
    const lookup = [
      { value: 1, symbol: "" },
      { value: 1e3, symbol: "k" },
      { value: 1e6, symbol: "M" },
      { value: 1e9, symbol: "B" },
      { value: 1e12, symbol: "T" },
      { value: 1e15, symbol: "P" },
      { value: 1e18, symbol: "E" }
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var item = lookup.slice().reverse().find(function(item) {
      return num >= item.value;
    });
    return item ? (parseFloat((num / item.value).toFixed(10))) + item.symbol : num;
  }
  

export const verifyWallet = async (signMessage, setAlert, setDisplayModal, walletAddress, projectId, ledger, sendTransaction, connection, setDisableSignBtn) => {
    try {
        setDisableSignBtn(true)
        const nonceReq = await fetch(`${Config.apiURL}/get-nonce`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                walletAddress: walletAddress,
                projectId: projectId
            })
        })
        const nonce = await nonceReq.json()
        if (nonce.error) return showAlert(setAlert, nonce.error)
        else {
            let signature;
            if (!ledger) {
                signature = await signMessage(new TextEncoder().encode(`${nonce.nonce}`))
                signature = bs58.encode(signature)
            } else {
                let tx = new Transaction();
                await tx.add(
                    new TransactionInstruction({
                        keys: [{ pubkey: walletAddress, isSigner: true, isWritable: true }],
                        data: Buffer.from(nonce.nonce, "utf-8"),
                        programId: new PublicKey("MemoSq4gqABAXKb96qnH8TysNcWxMyWCqXgDLGmfcHr"),
                    })
                )
                const blockHash = await connection.getLatestBlockhash();
                tx.feePayer = walletAddress;
                tx.recentBlockhash = blockHash.blockhash;

                signature = await sendTransaction(tx, connection)
                await connection.confirmTransaction({
                    blockhash: blockHash.blockhash,
                    lastValidBlockHeight: blockHash.lastValidBlockHeight,
                    signature
                });
                let checked = false
                for (var i = 0; i <= 10; i++) {
                    const response = await checkVerifiedWallet(walletAddress, projectId, nonce.id, signature)
                    if (response.nonce) {
                        await sleep(10000)
                        continue
                    } if (response.error) {
                        await sleep(10000)
                        continue
                    } else {
                        checked = true
                        break;
                    }
                }
                if (!checked) {
                    setDisableSignBtn(false)
                    return showAlert(setAlert, `Couldn't verify your wallet`)
                }
            }
            if (!signature) {
                setDisableSignBtn(false)
                return showAlert(setAlert, `An Error occured while signing`)
            }
            else {
                localStorage.setItem('nonceSignature', signature)
                localStorage.setItem('nonceId', nonce.id)
                localStorage.setItem('nonceExpiry', Date.now() + 7 * 24 * 60 * 60 * 1000)
                setDisplayModal(false)
                setDisableSignBtn(false)
                showAlert(setAlert, `Successfully signed in`, 'success')
            }
        }
    } catch (err) {
        setDisableSignBtn(false)
        console.log(err)
        showAlert(setAlert, `An unknown error occured.`)
    }
}

export const sleep = (time) => {
    return new Promise((resolve) => setTimeout(resolve, time));
}

export const showAlert = (setAlert, message, alert = 'danger') => {
    const id = crypto.randomBytes(16).toString('hex')
    setAlert((prevState) => ([
        ...prevState.slice(0, 3),
        { type: alert, message: message, id: id },
    ]))
    setTimeout(() => {
        hideAlert(setAlert, id)
    }, 10000);
}

export const hideAlert = (setAlert, id) => {
    setAlert((prevState) => ([
        ...prevState.filter(x => x.id !== id)
    ]))
}