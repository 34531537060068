import React, { useContext } from 'react'
import { StakingContext } from '../../../pages/tools/Staking'
import ConnectButton from '../../utility/Tools/ConnectButton'
import KingpinsSocials from '../../custom/Kingpins'
import HasukiDataTableBtn from '../../custom/Hasuki'

export default function StakingProjectInfo({ project, walletAddress, stakingProject }) {
    if(project.id === 'hasuki') return <HasukiDataTableBtn />
    else return (
        <>
            <div className='staking-tool__project-info-tab'>
                <div className='staking-tool__project-info'>
                    {project.id === 'kingpins' && (<KingpinsSocials />)}

                    <div className='flex justify-between items-center gap-[1rem]'>
                        <img src={project.logo} className='staking-tool__project-img' alt="Project" />
                    </div>

                    <div className='staking-tool__connect-btn'>
                        <div style={{ right: "20px" }}>
                            <ConnectButton project={project} walletAddress={walletAddress} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export const StakedStatus = () => {
    const { stakingProject, project } = useContext(StakingContext)
    return (
        <div className='staking__staked-status-flex'>
            <div className='font-signika staking-tool__purple-box-info-box'>
                <div className='staking-tool__purple-box-info' style={{ display: "block", margin: "auto" }}>
                    <div className='font-share-tech staking-tool__purple-box-info-title'>Total Staked</div>
                    <div className="font-signika">
                        <div className='flex justify-center items-center'>
                            <div className='purple-box-info__value'>
                                {stakingProject.totalStaked}
                            </div>
                            <div className='purple-box-info__value__total'>
                                /{stakingProject.supply}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='font-signika staking-tool__purple-box-info-box' style={project.id === 'kingpins' ? { display: 'none' } : {}}>
                <div className='staking-tool__purple-box-info' style={{ display: "block", margin: "auto" }}>
                    <div className='font-share-tech staking-tool__purple-box-info-title'>Your NFTs</div>
                    <div className="font-signika">
                        <div className='flex justify-center items-center'>
                            <div className='purple-box-info__value'>
                                {stakingProject.yourNfts}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='font-signika staking-tool__purple-box-info-box'>
                <div className='staking-tool__purple-box-info' style={{ display: "block", margin: "auto" }}>
                    <div className='font-share-tech staking-tool__purple-box-info-title'>Yours Staked</div>
                    <div className="font-signika">
                        <div className='flex justify-center items-center'>
                            <div className='purple-box-info__value'>
                                {stakingProject.yoursStakedNfts}
                            </div>

                            {project.id === 'kingpins' && <div className='purple-box-info__value__total'>
                                /{stakingProject.supply}
                            </div>}
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export const ProgressBar = () => {
    const { stakingProject } = useContext(StakingContext)
    return (
        <div>
            <div className='staking-tool__nfts-staked-progress-bar-bg'>
                <div className='staking-tool__nfts-staked-progress-bar-indicator'>{Math.floor(stakingProject.totalStaked * 100 / stakingProject.supply)}%</div>
                <div className="staking-tool__nfts-staked-progress-bar font-signika" style={{ width: `${Math.round(stakingProject.totalStaked / stakingProject.supply * 100)}%`, zIndex: '1' }}></div>
            </div>
        </div>
    )
}